import { Link } from '@remix-run/react';

export const Footer = (): React.ReactNode => {
	return (
		<footer className="footer">
			<div
				className="divider pre-footer"
				role="img"
				aria-label="Mountain-scape divider"
				aria-hidden="true"
			>
				<svg
					viewBox="0 0 2000 50"
					preserveAspectRatio="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill="currentColor"
						d="m0 12.502 15.625 1.993 15.625.191 15.625-1.538L62.5 11.883l15.625 2.562L93.75 13.34l15.625.914L125 18.51l15.625.252 15.625 2.043 15.625.513 15.625-1.257 15.625.51 15.625-3.376 15.625 1.136L250 15.558l15.625-1.977 15.625-.242 15.625-1.302L312.5 14.03l15.625 1.796 15.625-3.4 15.625.38L375 17.062l15.625-.602 15.625-2.54 15.625-.804 15.625.465 15.625.253 15.625 2.313 15.625 1.668L500 15.58l15.625 1.735 15.625 2.72 15.625 2.246 15.625-1.1 15.625.34 15.625-2.28 15.625-1.726L625 18.736l15.625-.98 15.625-4.467 15.625-.396 15.625.566 15.625-.372 15.625 2.856 15.625.05 15.625.044 15.625-.686 15.625 2.674 15.625.198 15.625-2.64 15.625.732 15.625-.324 15.625-.072L875 17.209l15.625 2.862 15.625-.675 15.625 1.174 15.625 3.316 15.625-1.38 15.625.774 15.625.205 15.625.333 15.625-.188 15.625.417 15.625.34 15.625 1.735 15.625-.954 15.625.852 15.625-1.95L1125 27.265l15.625 2.641 15.625.798 15.625-.49 15.625-.9 15.625-2.86 15.625-1.474 15.625 1.063 15.625 1.06 15.625-.217 15.625 3.54 15.625.83 15.625-2.252 15.625 2.232 15.625.77 15.625-1.926L1375 32.323l15.625 3.49 15.625.422 15.625-1.739 15.625-.06 15.625-3.892 15.625-1.563 15.625-1.325L1500 28.989l15.625-1.568 15.625-2.176 15.625-.72 15.625 3.31 15.625 2.091 15.625 3.34 15.625-2.932L1625 32.447l15.625.512 15.625 1.83 15.625 1.619 15.625-2.42 15.625 1.299 15.625-3.211 15.625-.27 15.625.154 15.625-.812 15.625 3.941 15.625-1.355 15.625-.6 15.625 3.68 15.625-1.384 15.625 1.126 15.625-.768 15.625-2.252 15.625 2.518 15.625.244 15.625-2.986 15.625 1.331 15.625-1.519 15.625 1.09L2000 32V50H0Z"
					></path>
				</svg>
			</div>
			<div className="container">
				<div className="footer-grid">
					<div>
						<Link to="/" rel="home" className="home-link">
							GList
						</Link>
						<p className="copyright">
							&copy; {new Date().getFullYear()} GList
						</p>
					</div>
					<div>
						<h2>Resources</h2>
						<ul className="footer--nav">
							<li className="footer--nav_item">
								<Link to="/features" prefetch="intent">
									Features
								</Link>
							</li>
							<li className="footer--nav_item">
								<Link to="/about" prefetch="intent">
									About
								</Link>
							</li>
							<li className="footer--nav_item">
								<Link to="/pricing" prefetch="intent">
									Pricing
								</Link>
							</li>
							<li className="footer--nav_item">
								<Link to="/changelog" prefetch="intent">
									Changelog
								</Link>
							</li>
						</ul>
					</div>
					<div>
						<h2>Contact</h2>
						<ul className="footer--nav">
							<li className="footer--nav_item">
								<Link to="/contact" prefetch="intent">
									Contact Form
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};
