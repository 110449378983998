import type {
	LinksFunction,
	LoaderFunctionArgs,
	MetaFunction,
} from '@remix-run/node';
import type { Socket } from 'socket.io-client';

import { data } from '@remix-run/node';
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteError,
	isRouteErrorResponse,
	useLoaderData,
	useNavigation,
} from '@remix-run/react';
import { ExternalScripts } from 'remix-utils/external-scripts';

import {
	commitSession,
	getUser,
	getUserSession,
} from './utils/session.server.ts';
import { connect } from '~/utils/ws.client.ts';
import { wsContext } from '~/contexts/ws.context.ts';

import { Header } from './components/organisms/Header/index.tsx';
import { Footer } from './components/organisms/Footer/index.tsx';
import { ErrorMessage } from './components/atoms/ErrorMessage/index.tsx';
import { IconCheck, IconCross } from './components/atoms/Icons/index.tsx';

import { useEffect, useState } from 'react';
import { MantineProvider, createTheme, ColorSchemeScript } from '@mantine/core';
import {
	Notifications,
	showNotification,
	updateNotification,
} from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { nprogress, NavigationProgress } from '@mantine/nprogress';

// ✅ Correct order – Button styles will override UnstyledButton styles
// import '@mantine/core/styles/UnstyledButton.css';
// import '@mantine/core/styles/Button.css';
// import '@mantine/core/styles/CloseButton.css';
// import '@mantine/core/styles/Group.css';
// import '@mantine/core/styles/Modal.css';
// import '@mantine/core/styles/ModalBase.css';
// import '@mantine/core/styles/Notification.css';
// import '@mantine/core/styles/Overlay.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/core';
import globalStylesUrl from '~/styles/global.css?url';
import globalSmallStylesUrl from '~/styles/global-small.css?url';
import globalMediumStylesUrl from '~/styles/global-medium.css?url';

const theme = createTheme({
	scale: 1.6,
});

export const links: LinksFunction = () => [
	{
		rel: 'stylesheet',
		href: globalStylesUrl,
	},
	{
		rel: 'stylesheet',
		href: globalSmallStylesUrl,
		media: 'print, (min-width: 414px)',
	},
	{
		rel: 'stylesheet',
		href: globalMediumStylesUrl,
		media: 'print, (min-width: 960px)',
	},
];

export const meta: MetaFunction = () => {
	return [
		{
			title: `GList`,
			'twitter:image': '',
			'twitter:card': 'summary_large_image',
			'twitter:creator': '@ahallicks',
			'twitter:site': '@ahallicks',
			'twitter:title': 'GList',
			'twitter:description':
				'All of your lists, grouped together, in one place',
		},
	];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const user = await getUser(request);
	const session = await getUserSession(request);
	const toastMessage = session.get('toastMessage') || null;
	if (!user) {
		return data(
			{
				user: null,
				toastMessage,
				ENV: {
					RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
				},
			},
			{
				headers: {
					// only necessary with cookieSessionStorage
					'Set-Cookie': await commitSession(session),
				},
			},
		);
	}
	return data(
		{
			user,
			toastMessage,
			ENV: {
				RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
			},
		},
		{
			headers: {
				// only necessary with cookieSessionStorage
				'Set-Cookie': await commitSession(session),
			},
		},
	);
};

/* const WebVitals = (): React.ReactNode => {
	const js = String.raw;
	return (
		<script
			suppressHydrationWarning
			dangerouslySetInnerHTML={{
				__html: js`(function () {
			const script = document.createElement('script');
			script.src = 'https://unpkg.com/web-vitals@3.5.0/dist/web-vitals.iife.js';
			script.onload = function () {
				webVitals.getCLS(console.log, { reportAllChanges: true });
				webVitals.getINP(console.log, { reportAllChanges: true });
			}
			document.head.appendChild(script);
		}())`,
			}}
		></script>
	);
}; */

function Document({
	title = 'GList',
	children,
}: {
	title?: string;
	children: React.ReactNode;
}): React.ReactNode {
	const { user, toastMessage } = useLoaderData<typeof loader>();
	const navigation = useNavigation();

	useEffect(() => {
		if (!toastMessage) {
			return;
		}
		const { message, type, id } = toastMessage;
		const autoClose = 4000;
		const defaultProps = {
			id: `${id}-notitification`,
			message,
			color: 'green',
			autoClose,
			className: 'glist-notification',
			closeButtonProps: {
				'aria-label': 'Close notification',
			},
		};

		switch (type) {
			case 'success':
				document.getElementById(`${id}-notitification`)
					? updateNotification(
							Object.assign({}, defaultProps, {
								title: 'Success',
								icon: <IconCheck width={18} height={18} />,
								autoClose: autoClose + 1,
							}),
						)
					: showNotification(
							Object.assign({}, defaultProps, {
								title: 'Success',
								icon: <IconCheck width={18} height={18} />,
							}),
						);
				break;
			case 'error':
				document.getElementById(`${id}-notitification`)
					? updateNotification(
							Object.assign({}, defaultProps, {
								color: 'red',
								title: 'There was a problem',
								autoClose: autoClose + 1,
								icon: <IconCross width={18} height={18} />,
							}),
						)
					: showNotification(
							Object.assign({}, defaultProps, {
								color: 'red',
								title: 'There was a problem',
								icon: <IconCross width={18} height={18} />,
							}),
						);
				break;
			default:
				document.getElementById(`${id}-notitification`)
					? updateNotification(
							Object.assign({}, defaultProps, {
								title: 'Notification',
								autoClose: autoClose + 1,
							}),
						)
					: showNotification(
							Object.assign({}, defaultProps, {
								title: 'Notification',
							}),
						);
			// throw new Error(`${type} is not handled`);
		}
	}, [toastMessage]);

	useEffect(() => {
		// when the state is idle then we can complete the progress bar
		if (navigation.state === 'idle') {
			nprogress.complete();
		}
		// and when it's something else it means it's either submitting a form or
		// waiting for the loaders of the next location so we start it
		else {
			nprogress.start();
		}
	}, [navigation.state]);

	return (
		<Layout title={title}>
			<div className="layout">
				<Header user={user} />
				{children}
				<Footer />
			</div>
		</Layout>
	);
}

const Layout = ({
	title = 'GList',
	children,
}: {
	title?: string;
	children: React.ReactNode;
}): React.ReactNode => {
	const [dark, setDark] = useState<boolean | null>(null);
	useEffect(() => {
		setDark(localStorage.getItem('dark-light-toggle') === 'dark');
	}, []);
	return (
		<html lang="en" className={dark ? 'toggle-mode' : ''}>
			<head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1"
				/>
				<Meta />
				<title>{title}</title>
				<style
					dangerouslySetInnerHTML={{
						__html: `@font-face {font-display: swap;font-family: 'Poppins';font-style: normal;font-weight: 300;src: local('Poppins'),url('/font/poppins/poppins-v20-latin-300.woff2') format('woff2'),url('/font/poppins/poppins-v20-latin-300.woff') format('woff');}@font-face {font-display: swap;font-family: 'Poppins';font-style: normal;font-weight: 400;src: local('Poppins'),url('/font/poppins/poppins-v20-latin-regular.woff2') format('woff2'),url('/font/poppins/poppins-v20-latin-regular.woff') format('woff');}@font-face {font-display: swap;font-family: 'Poppins';font-style: normal;font-weight: 500;src: local('Poppins'),url('/font/poppins/poppins-v20-latin-500.woff2') format('woff2'),url('/font/poppins/poppins-v20-latin-500.woff') format('woff');}`,
					}}
				/>
				<Links />
				<ColorSchemeScript />
			</head>
			<body>
				{children}
				<button
					className="dark-light"
					aria-label="Toggle dark/light mode"
					onClick={() => {
						setDark((prev) => !prev);
						localStorage.setItem(
							'dark-light-toggle',
							dark ? 'light' : 'dark',
						);
					}}
				>
					<svg
						width="28"
						height="28"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth="1.5"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
					</svg>
				</button>
				{/* {process.env.NODE_ENV === 'development' ? (
					<>
						<script src="/script/axe.min.js" defer></script>
						<script src="/script/run-axe.js" defer></script>
						<WebVitals />
					</>
				) : null} */}
			</body>
		</html>
	);
};

export default function App(): React.ReactNode {
	const [socket, setSocket] = useState<Socket | null>(null);

	useEffect(() => {
		const connection = connect();
		setSocket(connection);
		return () => {
			connection.close();
		};
	}, []);
	return (
		<Document>
			<MantineProvider theme={theme}>
				<ModalsProvider>
					<wsContext.Provider value={socket}>
						<NavigationProgress />
						<Notifications position="top-center" limit={2} />
						<Outlet />
						<ScrollRestoration
							getKey={(location) => {
								return location.pathname;
							}}
						/>
						<ExternalScripts />
						<Scripts />
					</wsContext.Provider>
				</ModalsProvider>
			</MantineProvider>
		</Document>
	);
}

export function ErrorBoundary(): React.ReactNode {
	const error = useRouteError();
	if (isRouteErrorResponse(error)) {
		return (
			<Layout title={`${error.status} ${error.statusText}`}>
				<div className="layout">
					<Header user={null} nologin={true} />
					<main
						id="main"
						className="groups-main main-section error-wrap"
					>
						<div className="container">
							<ErrorMessage
								title={`Oops: ${error.status} Error`}
								message={error.data}
							/>
						</div>
					</main>
					<Footer />
				</div>
			</Layout>
		);
	}

	console.error(error);
	return (
		<Layout title="Oh no!">
			<div className="layout">
				<Header user={null} nologin={true} />
				<main id="main" className="groups-main main-section error-wrap">
					<div className="container">
						<ErrorMessage
							title="Something went wrong"
							message="Oh no, something did not go well."
						/>
					</div>
				</main>
				<Footer />
			</div>
		</Layout>
	);
}
